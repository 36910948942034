import { debounce, negate, pickBy } from 'lodash-es'
import numbro from 'numbro'
import { formatISO, intlFormatDistance, parseISO } from 'date-fns'
import { currentLocale } from '../i18n'

export { v4 as randomUUID } from 'uuid'

// Generic
export const extractErrorParams = error => JSON.parse(JSON.stringify(error, Object.getOwnPropertyNames(error)))
export const assert = (condition, message) => {
  if (!condition) {
    throw new Error('Assertion failed: ' + message)
  }
}

// Predicates
export const nonNull = value => value != null
export const distinct = (item, index, array) => array.indexOf(item) === index
export const isFieldEmpty = value => value == null || (typeof value === 'string' && String(value).trim() === '')

// Unit conversions
export const toKmh = ms => ms && Math.round(ms * 3.6)
export const toMs = kmh => kmh && kmh / 3.6

// Rounding
const genericPrecision = 10_000_000
const aboutSame = (a, b) => Math.abs(a - b) < 1 / genericPrecision
export const aboutZero = value => !value || aboutSame(value, 0)
export const createRound = precision => value => Math.round(value * precision) / precision
export const toPrecision = precision => value => Number(Number(value).toPrecision(precision))
const distancePrecision = 100
export const roundDistance = createRound(distancePrecision)
export const roundVoltage = voltage => Math.round(voltage || 0)
export const roundCurrent = current => Math.round(current || 0)

// Formatting
export const cut = (length, text) =>
  length && text?.length > length
    ? String(text).substring(0, length) + '…'
    : text
export const formatLocaleDate = date => new Date(date).toLocaleDateString(currentLocale.value)
const dateTimeFormat = () =>
  Intl.DateTimeFormat(currentLocale.value, { dateStyle: 'medium', timeStyle: 'short' })
export const formatDateTime = date => date && dateTimeFormat().format(new Date(date))
export const formatISODate = value => formatISO(value, { representation: 'date' })
export const formatDuration = seconds =>
  new Date(1_000 * (seconds || 0))
    .toISOString()
    .substring(11, 11 + 8)
export const formatTimeDistance = date =>
  intlFormatDistance(parseISO(date), new Date(), { locale: currentLocale.value })
export const formatPercent = (value, format = {
  output: 'percent',
  spaceSeparated: numbro.languageData().spaceSeparated ?? false, // Boolean required by numbro type checks #MAT-540
  totalLength: 2
}) =>
  numbro(value).format(format)
const formatNumber = format => value => value == null ? '' : numbro(value).format(format)
export const formatInteger = formatNumber({ thousandSeparated: true, mantissa: 0 })
export const formatDecimal = mantissa => formatNumber({ thousandSeparated: true, mantissa })

// HTML
export const blurFocusedElement = (selector, { optional } = {}) => {
  const element = window.document.querySelector(`${selector} *:focus`)
  assert(element || optional, 'No focused element found.')
  element?.blur()
}
export const altKey = key => event => {
  const match = event.altKey && event.code === key
  if (match) {
    event.preventDefault()
  }
  return match
}

// Forms
export const createMatches = searchRef => value =>
  !searchRef.value || value?.toLowerCase().includes(searchRef.value.toLowerCase())

export const stringArrayToBooleans = array =>
  Object.fromEntries(
    (array ?? []).map(key => [key, true])
  )
export const booleansToStringArray = booleans =>
  Object.entries(booleans)
    .filter(([_, value]) => value)
    .map(([key]) => key)

export const debounceDelayOnTyping = 400
export const debounceOnTyping = fn => debounce(fn, debounceDelayOnTyping)

export const toQuery = formData => pickBy(formData, negate(isFieldEmpty))

// Timing
export const timeNow = () => window.performance.now()
export const getDuration = before => Math.round(timeNow() - before)
export const time = async (fn, callback) => {
  const before = timeNow()
  let exception
  try {
    return await fn()
  } catch (error) {
    exception = error
    throw error
  } finally {
    callback(getDuration(before), exception)
  }
}

export const throttle = (fn, wait) => {
  let last
  return (...args) => {
    const now = timeNow()
    if (last == null || now - last > wait) {
      last = now
      return fn(...args)
    }
  }
}

// Arrays
export const toSpliced = (array, start, deleteCount, ...items) =>
  [
    ...array.slice(0, start),
    ...items,
    ...array.slice(start + deleteCount)
  ]
export const replaceArrayElement = (array, index, element) => toSpliced(array, index, 1, element)
