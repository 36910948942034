<script setup>
import { setDefaultModule } from './module/module-logic'
import SystemLoadingPanel from './components/SystemLoadingPanel'
import ErrorToasts from './error-toast/ErrorToasts'
import UserMessageToast from './components/UserMessageToast'

setDefaultModule()

/* eslint-disable max-len */
</script>

<template>
  <router-view />
  <SystemLoadingPanel />
  <ErrorToasts />
  <UserMessageToast />
</template>

<style>
html {
  font-size: 0.75rem;
}

@media print {
  html {
    font-size: 16px;
  }

  #app {
    padding: 1cm 1cm 0;
  }

  /* hide vertexes in print */
  g[data-type=vertices] g[data-type=main] circle[data-type=circle] {
    display: none !important;
  }
}

.mtr-editor-right-panel {
  position: absolute;
  right: 10px;
  opacity: 95%;
  overflow-y: auto;
}

.mtr-layout-left-panel {
  position: absolute;
  left: 10px;
  opacity: 95%;
  overflow-y: auto;
}

.mtr-btn-sm-icon {
  padding: 3px 10px 2px 10px;
}

.mtr-lh-inherit {
  line-height: inherit !important;
}

.mtr-on-top {
  z-index: 9;
}

.lh-sm .form-label {
  margin-bottom: 0.25rem;
}

svg text {
  font-family: inherit;
}

.mtr-report-container {
  width: 58rem;
}

.mtr-text-overflow-ellipsis {
  text-overflow: ellipsis;
}

.mtr-text-decoration-line-none {
  text-decoration-line: none;
}

.mtr-keep-with-next {
  break-after: avoid-page;
}

.mtr-avoid-page-break {
  break-inside: avoid-page;
}

.mtr-cursor-pointer {
  cursor: pointer;
}

p:last-child {
  margin-bottom: 0;
}

.alert {
  margin-top: 1rem;
  margin-bottom: 0;
}

tr.text-muted td,
tr.text-muted th {
  color: inherit;
}

.mtr-docs-link-card {
  width: 14rem;
  min-height: 8rem;
}

.mtr-docs-image-link-card {
  width: 20rem;
  min-height: 22rem;
}

.mtr-docs-link-card:hover, a:focus .mtr-docs-link-card,
.mtr-docs-image-link-card:hover, a:focus .mtr-docs-image-link-card {
  background-color: #f8f8fa;
}

.mtr-docs-img {
  object-fit: cover;
}

.mtr-docs-do {
  border-top-width: 1rem !important;
  margin-bottom: -0.25rem;
}

div.mtr-docs-video-cover.paused::before {
  content: "";
  position: absolute;
  background-color: #00000080;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url('data:image/svg+xml;utf-8, <svg xmlns= "http://www.w3.org/2000/svg" enable-background= "new 0 0 24 24" viewBox= "0 0 24 24" fill= "white" width= "48px" height= "48px" > <g> <rect fill= "none" height= "24" width= "24" /></g><g><path d= "M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M9.5,16.5v-9l7,4.5L9.5,16.5z" /> </g> </svg>');
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 50%;
  background-size: 72px;
}

div.mtr-docs-video-cover.playing:hover::before {
  content: "";
  position: absolute;
  background-color: #00000040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill="white" width="48px" height="48px"><g><rect fill="none" height="24" width="24"/></g><g><g><path d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M11,16H9V8h2V16z M15,16h-2V8h2V16z"/></g></g></svg>');
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 50%;
  background-size: 72px;
}

.table-sticky-head thead {
  position: sticky;
  top: 3.4rem;
}

/* Customize Bootstrap 5.3 range slider track color to darker gray as of v. 5.2 */
.form-range::-webkit-slider-runnable-track {
  background-color: #dee2e6;
}
</style>
