import axios from 'axios'
import { time } from './fns'
import { newErrorToast } from '../error-toast/error-toast-state'
import { setAuthentication } from '../api-config'
import { etagToVersion, withIfMatch } from './api-version'
import { setRequestId, withRequestIds } from './api-request-id'
import { setRequestedPathname } from './local-storage'
import { submitOpsEvent } from '../ops-log/ops-log-api'

const apiOpsLogThreshold = 0 // 100?

let firstApiCall = true

const logAsJson = value => value ? JSON.stringify(value) : ''
const hidePassword = data => typeof data === 'object' && data.password ? { ...data, password: '*****' } : data

const processApiCall = (type, url, opsLog, fn) => {
  const identifier = setRequestId()
  return time(fn, (duration, exception) => {
    const isError = exception && exception?.response?.status !== 401
    if (opsLog && (isError || duration >= apiOpsLogThreshold)) {
      const event = {
        severity: isError ? 'error' : duration > 200 ? 'warning' : 'info',
        activity: `${type} ${axios.defaults.baseURL}${url}`,
        identifier,
        name: 'request',
        data: { duration, exception }
      }
      submitOpsEvent(event)
    }
  })
}

export const fetch = async (url, config, { handleError = true, opsLog = true } = {}) =>
  processApiCall('GET', url, opsLog, async () => {
    try {
      console.debug('(api) GET >', url, logAsJson(config))
      const response = await axios.get(url, withRequestIds(config))
      const etag = response.headers?.etag
      const version = etag ? ' ' + etagToVersion(etag) : ''
      console.log('(api) GET <', url + version, response?.data === '' ? '<empty>' : response?.data ?? response)
      return response
    } catch (error) {
      console.log('(api) GET X', url, error.response ?? error)
      if (handleError) {
        handleApiError(error)
      }
      throw error
    } finally {
      if (firstApiCall) {
        firstApiCall = false
      }
    }
  })

export const get = async (url, config, settings) => (await fetch(url, config, settings)).data

export const getVersioned = async (url, config) => {
  const { data, headers: { etag } } = await fetch(url, config)
  return { version: etagToVersion(etag), ...data }
}

export const create = async (url, data = undefined, config = undefined,
  { handleError = true, opsLog = true } = {}) =>
  processApiCall('POST', url, opsLog, async () => {
    try {
      console.log('(api) POST >', url, hidePassword(data), logAsJson(config))
      const response = await axios.post(url, data, withRequestIds(config))
      const { location } = response.headers
      const id = Number(location)
      const result = location == null || isNaN(id) ? location : id
      console.log('(api) POST <', url, result, result == null ? response : '')
      return result
    } catch (error) {
      console.log('(api) POST X', url, error.response ?? error)
      if (handleError) {
        handleApiError(error)
      }
      throw error
    }
  })

export const update = async (url, version, data, config = undefined,
  { handleError = true, opsLog = true } = {}) =>
  processApiCall('PUT', url, opsLog, async () => {
    try {
      console.log('(api) PUT >', url, version, hidePassword(data), logAsJson(config))
      const response = await axios.put(url, data, withRequestIds(withIfMatch(version, config)))
      console.log('(api) PUT <', url, version, response)
      return response
    } catch (error) {
      console.log('(api) PUT X', url, version, error.response ?? error)
      if (handleError) {
        handleApiError(error)
      }
      throw error
    }
  })

export const patch = async (url, version, data, config = undefined,
  { handleError = true, opsLog = true } = {}) =>
  processApiCall('PATCH', url, opsLog, async () => {
    try {
      console.log('(api) PATCH >', url, version, hidePassword(data), logAsJson(config))
      const response = await axios.patch(url, data, withRequestIds(withIfMatch(version, config)))
      console.log('(api) PATCH <', url, version, response)
      return response
    } catch (error) {
      console.log('(api) PATCH X', url, version, error.response ?? error)
      if (handleError) {
        handleApiError(error)
      }
      throw error
    }
  })

export const delete_ = async (url, version, config = undefined,
  { handleError = true, opsLog = true } = {}) =>
  processApiCall('DELETE', url, opsLog, async () => {
    try {
      console.log('(api) DELETE >', url, version, logAsJson(config))
      const response = await axios.delete(url, withRequestIds(withIfMatch(version, config)))
      console.log('(api) DELETE <', url, version, response)
      return response
    } catch (error) {
      console.log('(api) DELETE X', url, version, error.response ?? error)
      if (handleError) {
        handleApiError(error)
      }
      throw error
    }
  })

export const handleApiError = error => {
  if (!error.response) {
    newErrorToast.value = 'You seem to be disconnected.'
    return
  }
  if (error.response.status === 401) {
    if (window.location.pathname !== '/login') {
      setRequestedPathname(window.location.pathname)
      if (firstApiCall) {
        window.location = '/login'
      }
    }
    if (!firstApiCall) {
      newErrorToast.value = { text: 'You have been logged out.', action: { label: 'Log in', url: '/login' } }
    }
    setAuthentication()
    return
  }
  if (error.response.status === 403) {
    newErrorToast.value = 'You do not have permission for this action.'
    return
  }
  if (error.response.status === 422) {
    newErrorToast.value = 'Invalid data.'
    return
  }
  if (error.response.status === 409) {
    newErrorToast.value = {
      text: 'The data you are trying to change has already been changed in the meantime.' +
        ' Please reload current data first.',
      action: { label: 'Reload', url: window.location }
    }
    return
  }
  newErrorToast.value = error.response.data?.error || 'There was an error while connecting to the server.'
}

export const hasFieldValidationError = (error, path, errorCode) =>
  error.response?.status === 422 &&
  error.response.data.errors
    .filter(fieldError => fieldError.path === path && fieldError.errorCode === errorCode)
    .length
