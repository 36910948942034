import useSWRV, { mutate } from 'swrv'
import { toQuery } from '../shared/fns'
import { create, get, getVersioned, patch, update } from '../shared/api'
import { setCurrentUser } from '../shared/local-storage'
import { anonymous } from '../api-config'

export const getUsers = async params => await get('/users', { params })
export const getUser = async id => await get(`/users/${id}`)

export const useUsers = params => useSWRV(
  () => `users|${JSON.stringify(toQuery(params))}`,
  () => getUsers(toQuery(params))
)

export const useUser = id =>
  useSWRV(() => id != null && `/users/${id}`, getVersioned, { revalidateOnFocus: false })

export const createUser = async (data, options) => {
  await create('/users', data, null, options)
}

export const updateUser = async (id, version, data, options) => {
  await update(`/users/${id}`, version, data, null, options)
}

export const archiveUser = async (id, version) => {
  await patch(`/users/${id}`, version, { archived: true })
}

export const restoreUser = async (id, version) => {
  await patch(`/users/${id}`, version, { archived: false })
}

export const setUserPassword = async (id, version, password) => {
  await patch(`/users/${id}`, version, { password })
}

const url = '/my-user'

export const getMyUser = async () => {
  const user = await getVersioned(url)
  setCurrentUser(user)
  await mutate(url, user)
  return user
}

export const useMyUser = () => useSWRV(url, getVersioned, { revalidateOnFocus: false, dedupingInterval: 5_000 })

export const updateMyUserPassword = async (version, password) => {
  await patch(url, version, { password })
}

export const updateMyUser = async (version, data, options) => {
  await update(url, version, data, null, options)
}

export const requestPasswordReset = async (data, options) =>
  await anonymous(create)('/password-reset-tokens', data, null, options)

export const resetPassword = async (token, data, options) =>
  await anonymous(create)(`/password-reset-tokens/${token}`, data, options)
